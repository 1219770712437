import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Vietnam",
  "subtitle": "October 2018",
  "category": "Asia"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.grab.com/kh/en/"
        }}>{`GRAB`}</a>{` - Basically Uber`}</li>
    </ul>
    <h2 {...{
      "id": "thành-phố-hồ-chí-minh"
    }}>{`Thành phố Hồ Chí Minh`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/B%C3%A1nh_tr%C3%A1ng_n%C6%B0%E1%BB%9Bng"
        }}>{`Bánh tráng nướng`}</a>{` - Sold by street vendors`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XQUt6gMD4ECepron9"
        }}>{`Cục Gạch Quán`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Vietnamese country side cuisine in a colonial villa. Reservations recommended.`}</li>
          <li parentName="ul">{`Liked - Cua lột rang muối, Mực sốt me, Thịt khìa`}</li>
          <li parentName="ul">{`Smoothies were all good - Coconut, Orange, Passionfruit`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/niNohL575qCATE4NA"
        }}>{`Mama Pho`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/rnJDLb8ZSQgtG7iN7"
        }}>{`A O (Show)`}</a>{` - By `}<a parentName="li" {...{
          "href": "https://www.luneproduction.com/"
        }}>{`Lune productions`}</a>{` at the `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qWQPUTzMRbXzZTPz5"
        }}>{`Saigon Opera House`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/eAtCCxq44XdpWFye7"
        }}>{`Fine Arts Museum`}</a>{` - Browsed around for 2 hours, no air conditioning`}</li>
    </ul>
    <h4 {...{
      "id": "spas"
    }}>{`Spas`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.miumiuspa.com/"
        }}>{`Miu Miu Spa`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/aSKfABf9iLvhxzLv6"
        }}>{`Saigon Heritage Spa`}</a></li>
    </ul>
    <h2 {...{
      "id": "cần-thơ"
    }}>{`Cần Thơ`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ZspDoZnrEQyS8Src6"
        }}>{`Cai Rang Floating Market`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Go early morning 6:00 - 7:00 when the locals go`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/B%C3%A1nh_m%C3%AC"
            }}>{`Bánh mì`}</a>{` and `}<a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Cellophane_noodles"
            }}>{`Glass Noodles`}</a>{` are a must`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QeBgoeYQ555EBrYY7"
        }}>{`Chợ đêm Ninh Kiều`}</a>{` - Night market`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/B2cs8xSTMoyyh15Y7"
        }}>{`Ninh Kieu Wharf`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4Zhr34Dw2kVufHpE7"
        }}>{`Quán Bánh xèo 7 Tới`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/B%C3%A1nh_x%C3%A8o"
            }}>{`Bánh xèo`}</a>{` - Big sizzling pancake`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://vi.wikipedia.org/wiki/B%C3%A1nh_kh%E1%BB%8Dt"
            }}>{`Bánh khọt`}</a>{` - Mini pancake`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "hà-nội"
    }}>{`Hà Nội`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QRh17YNCsr3Sh6x37"
        }}>{`Bami Bread`}</a>{` - `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/B%C3%A1nh_m%C3%AC"
        }}>{`Bánh mì`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/B%C3%A1nh_%C4%91%E1%BA%ADu_xanh"
        }}>{`Bánh đậu xanh`}</a>{` - Green Bean Cake from Hải Dương province`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/nWvKmKWNdupARTrr6"
            }}>{`Rồng Vàng Minh Ngọc`}</a>{` - Highly recommend`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/BbPM1FJQRPsQornn6"
            }}>{`Ô Mai Hồng Lam`}</a>{` - Many locations`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/w7rAmMRLAYWgFvov9"
        }}>{`Bun Cha Ta`}</a>{` - Popular lunch dish `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Bun_cha"
        }}>{`Bún chả`}</a>{` is served all day`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.cafegiang.vn/"
        }}>{`Cafe Giảng`}</a>{` - Whole block specializes in `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Egg_coffee"
        }}>{`Cà phê trứng`}</a>{` (Egg Coffee)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/vg64acp2gsGjogij9"
        }}>{`Garden House Villa`}</a>{` - Really liked the Garden House Special Royal Shrimps`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/azS222am2ZnCcf438"
        }}>{`Kem Tràng Tiền`}</a>{` - Old school sticky rice ice cream`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/5xGD5deSzUZiPeoV7"
        }}>{`Little Bowl`}</a>{` - Traditional Vietnamese dessert made of rice jelly and ice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/H1bWeQFRYFrVPM2T6"
        }}>{`Xôi Sòn Cầu Gỗ`}</a>{` - Very popular with locals and she doesn't speak any English. Closes when she runs out of food. Marinated pork belly or beef, Egg and Sticky Rice for 40,000 VND!`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mixb6DQiBxuKDepS7"
        }}>{`Chợ Hàng Mã`}</a>{` - Night market`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/YA157Bo6NSgsRvLS6"
        }}>{`Hoàn Kiếm Lake`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Ho%C3%A0n_Ki%E1%BA%BFm_Lake"
        }}>{`wiki`}</a>{`) - Saturday night was very lively`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.luneproduction.com/lang-toi"
        }}>{`Làng Tôi (Show)`}</a>{` - By `}<a parentName="li" {...{
          "href": "https://www.luneproduction.com/"
        }}>{`Lune productions`}</a>{` at the `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MV6Eff3rTLJYv3xeA"
        }}>{`Hanoi Opera House`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QkNjCGpNrpQQn9gc7"
        }}>{`National Fine Arts Museum`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/sxu74dirVghesNyx7"
        }}>{`Vietnam Museum Of Ethnology`}</a>{` - Large open air museum`}</li>
    </ul>
    <h4 {...{
      "id": "spas-1"
    }}>{`Spas`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/pXsaLPgkDM8H6XUr6"
        }}>{`Mido Spa`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/WfuLfbefbU5k2pE58"
        }}>{`Omamori Spa`}</a></li>
    </ul>
    <h2 {...{
      "id": "hạ-long-bay"
    }}>{`Hạ Long Bay`}</h2>
    <ul>
      <li parentName="ul">{`Enjoyed my time with `}<a parentName="li" {...{
          "href": "https://eracruises.com/"
        }}>{`Era Cruise`}</a>{` that departed from `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/aCGG8WvpW8hJfRJp6"
        }}>{`Hải Phòng`}</a></li>
      <li parentName="ul">{`Kayaking and biking on local islands`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      